import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82')
];

export const server_loads = [];

export const dictionary = {
		"/(customer)": [19,[2]],
		"/(static)/about": [52,[8]],
		"/(customer)/account": [20,[2,3]],
		"/(customer)/account/email": [21,[2,3]],
		"/(customer)/account/name": [22,[2,3]],
		"/(customer)/account/notifications": [23,[2,3]],
		"/(customer)/account/password": [24,[2,3]],
		"/(customer)/account/phone-number": [25,[2,3]],
		"/(customer)/auth-actions": [26,[2]],
		"/(customer)/auth-actions/(get-oob-code)/change-email": [27,[2,4]],
		"/(customer)/auth-actions/(get-oob-code)/generic": [28,[2,4]],
		"/(customer)/auth-actions/(get-oob-code)/recover-email": [29,[2,4]],
		"/(customer)/auth-actions/(get-oob-code)/reset-password": [30,[2,4]],
		"/(customer)/auth-actions/(get-oob-code)/verify-email": [31,[2,4]],
		"/business-login": [78],
		"/business-login/issues": [79],
		"/(customer)/business/[businessId]/(browse)": [32,[2,5]],
		"/(customer)/business/[businessId]/cart": [33,[2,5]],
		"/(customer)/business/[businessId]/category/[categoryId]/item/[itemId]": [34,[2,5]],
		"/(customer)/business/[businessId]/checkout": [35,[2,5,6]],
		"/(customer)/business/[businessId]/checkout/finish": [36,[2,5,6]],
		"/(customer)/business/[businessId]/checkout/location": [37,[2,5,6]],
		"/(customer)/business/[businessId]/checkout/retrieval": [38,[2,5,6]],
		"/(customer)/business/[businessId]/combo/[comboId]": [39,[2,5]],
		"/(customer)/business/[businessId]/locations": [40,[2,5]],
		"/(customer)/business/[businessId]/manual-order": [41,[2,5]],
		"/(customer)/business/[businessId]/orders": [42,[2,5,7]],
		"/(customer)/business/[businessId]/orders/[orderId]": [43,[2,5,7]],
		"/b/business/delivery": [54,[9,10]],
		"/b/business/general": [55,[9,10]],
		"/b/business/locations": [56,[9,10,11]],
		"/b/business/locations/[businessLocationId]": [57,[9,10,11]],
		"/b/business/owner": [58,[9,10]],
		"/b/customers": [59,[9]],
		"/b/dashboard": [60,[9,12]],
		"/b/dashboard/[locationUserUid]": [61,[9,12]],
		"/b/menu": [62,[9,13]],
		"/b/menu/category/create": [68,[9,13]],
		"/b/menu/category/[categoryId]": [63,[9,13,14]],
		"/b/menu/category/[categoryId]/item/create": [66,[9,13,14]],
		"/b/menu/category/[categoryId]/item/[itemId]": [64,[9,13,14,15]],
		"/b/menu/category/[categoryId]/item/[itemId]/update": [65,[9,13,14,15]],
		"/b/menu/category/[categoryId]/update": [67,[9,13,14]],
		"/b/menu/combo": [69,[9,13,16]],
		"/b/menu/combo/create": [72,[9,13,16]],
		"/b/menu/combo/[comboId]": [70,[9,13,16,17]],
		"/b/menu/combo/[comboId]/update": [71,[9,13,16,17]],
		"/b/menu/layout": [73,[9,13]],
		"/b/order": [74,[9,18]],
		"/b/order/all": [75,[9,18]],
		"/b/user": [76,[9]],
		"/b/user/notifications": [77,[9]],
		"/(customer)/carts": [44,[2]],
		"/(static)/contact": [53,[8]],
		"/contract": [80],
		"/(customer)/forgot-password": [45,[2]],
		"/go/[businessId]": [81],
		"/(customer)/login": [46,[2]],
		"/(customer)/onboarding": [47,[2]],
		"/(customer)/orders": [48,[2]],
		"/(customer)/reauth": [49,[2]],
		"/(customer)/sign-up": [50,[2]],
		"/util/encode-login": [82],
		"/(customer)/verify-phone": [51,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';