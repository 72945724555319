import { PUBLIC_ENV } from "$env/static/public";
import { handleErrorWithSentry, replayIntegration } from "@sentry/sveltekit";
import * as Sentry from '@sentry/sveltekit';

const SENTRY_ENABLED = PUBLIC_ENV === "PROD";

Sentry.init({
  enabled: SENTRY_ENABLED,
  dsn: 'https://f34f59106efbaa114ee1f501b1515a56@o4507058111709184.ingest.us.sentry.io/4507058117738496',
  tracesSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  // If you don't want to use Session Replay, just remove the line below:
  integrations: [replayIntegration()],
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError =  handleErrorWithSentry();
